@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.app__aboutus {
  position: relative;
}

.app__aboutus-overlay {
  position: absolute;
  inset: 0;
}

.app__aboutus-overlay img {
  width: 220px;
  height: 240px;
  z-index: 0;
}

.app__aboutus-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.app__aboutus-content_about,
.app__aboutus-content_history {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 20px; /* Ensure equal spacing on both sides */
}

.app__aboutus-content_about h1,
.app__aboutus-content_history h1 {
  font-size: 2.5rem; /* Reduced font size */
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
  margin: 1rem 0;
  color: var(--color-grey);
  font-size: 1rem; /* Reduced font size */
}

.app__aboutus-content_knife {
  flex: 0.5; /* Adjusted to be smaller and fit better */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px; /* Ensure equal spacing on both sides */
}

.app__aboutus-content_knife img {
  height: 450px;
   /* Reduced image size */
}

.p__opensans {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: color 0.3s ease-in-out;
  font-size: 0.875rem; /* Reduced font size */
}

.p__opensans span {
  white-space: nowrap; /* Prevent line breaks */
}

.p__opensans:hover {
  color: #ff6347; /* Change to your preferred color */
}

.cup-profile {
  font-weight: bold;
  transition: transform 4.9s ease-in-out, opacity 0.3s ease-in-out;
  animation: blinkAnimation 1s infinite alternate;
  font-size:  2rem; /* Reduced font size */
}

@keyframes blinkAnimation {
  0%, 100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    transform: translateY(-5px);
  }
}

.cup-profile:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.custom__button {
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
  font-size: 0.875rem; /* Reduced font size */
}

.custom__button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.75s;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
}

.custom__button:active::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

@media screen and (max-width: 900px) {
  .app__aboutus-content {
    flex-direction: column;
  }

  .app__aboutus-content_knife {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 650px) {
  .app__aboutus-overlay img {
    width: 50%;
    height: 200px;
  }
}
