@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  position: relative;
}

.special-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  padding: 15px; /* Reduced padding */
  position: relative;
  z-index: 1;
}

.special-menu-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/watermark.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: blur(5px) brightness(70%);
  z-index: -1;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem; /* Reduced padding */
}

.export-packing-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 15px; /* Reduced padding */
}

.truck {
  background-color: rgba(255, 255, 255, 0.8);
  width: 40%; /* Adjusted width */
  max-width: 500px; /* Reduced max-width */
  height: auto;
  padding: 15px; /* Reduced padding */
  border-radius: 12px; /* Adjusted border-radius */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideIn 2s ease-in-out;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.middle-image {
  width: 70%; /* Adjusted width */
  max-width: 400px; /* Reduced max-width */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-image img {
  width: 100%;
  height: auto;
}

.packing-details {
  background-color: var(--color-grey);
  padding: 20px; /* Reduced padding */
  border-radius: 12px; /* Adjusted border-radius */
  text-align: center;
  width: 100%;
  animation: fadeIn 2s ease-in-out;
}

.packing-details h2 {
  margin-top: 0;
  color: #333;
  font-weight: 700;
  font-size: 1.5em; /* Reduced font-size */
}

.packing-details ul {
  list-style-type: none;
  padding: 0;
  color: #333;
  font-weight: 400;
  font-size: 1em; /* Reduced font-size */
  animation: fadeIn 2s ease-in-out;
}

.packing-details li {
  margin: 10px 0; /* Reduced margin */
  background-color: #f1f1f1;
  padding: 8px; /* Reduced padding */
  border-radius: 8px; /* Adjusted border-radius */
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
  animation: none;
}

.packing-details li.fade-in {
  animation: fadeInUp 7.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.packing-details li:hover {
  transform: scale(1.05);
  background-color: #e0e0e0;
}

/* Media query for tablets and larger screens */
@media (max-width: 1024px) {
  .special-menu-container {
    padding: 10px; /* Further reduced padding */
  }

  .container {
    padding: 1rem; /* Further reduced padding */
  }

  .export-packing-container {
    flex-direction: column; /* Stack items vertically */
    padding: 10px; /* Further reduced padding */
  }

  .truck, .packing-details {
    width: 90%; /* Adjusted width */
    max-width: 100%; /* Adjusted max-width */
  }

  .middle-image {
    width: 90%; /* Adjusted width */
    max-width: 300px; /* Further reduced max-width */
  }

  .packing-details h2 {
    font-size: 1.3em; /* Further reduced font-size */
  }

  .packing-details ul {
    font-size: 0.9em; /* Further reduced font-size */
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .special-menu-container {
    padding: 5px; /* Further reduced padding */
  }

  .container {
    padding: 0.5rem;
     /* Further reduced padding */
  }
  .container p{
    font-size: 1rem;
  }

  .export-packing-container {
    flex-direction: column; /* Stack items vertically */
    padding: 5px; /* Further reduced padding */
  }

  .truck, .packing-details {
    width: 100%; /* Full width */
    max-width: 100%; /* Full width */
  }

  .middle-image {
    width: 100%; /* Full width */
    max-width: 250px; /* Further reduced max-width */
  }

  .packing-details h2 {
    font-size: 1.2em; /* Further reduced font-size */
  }

  .packing-details ul {
    font-size: 0.8em; /* Further reduced font-size */
  }
}
