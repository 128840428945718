.app__header {
    background-color: var(--color-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 20px; /* Reduced padding */
}

.app-header {
    font-family: var(--font-base);
    color: var(--color-golden);
    letter-spacing: 0.05cm;
    text-transform: uppercase;
    line-height: 1.2;
    font-size: 4rem; /* Reduced font size */
}

.app__wrapper_info {
    flex: 1;
    margin-left: 30px; /* Reduced margin */
    margin-top: -20px; /* Reduced margin */
}

.app__wrapper_img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__wrapper_img img {
    max-width: 100%;
    height: auto;
    border: 5px solid rgba(64, 228, 18, 0.432); /* Reduced border size */
    border-radius: 10px; /* Reduced border radius */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); /* Reduced shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.app__wrapper_img img:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); /* Reduced hover shadow */
}

.text-semibold {
    font-weight: 600;
}

.text-gray-300 {
    color: #d1d5db; /* Tailwind's gray-300 color */
}

@media screen and (max-width: 1200px) {
    .app__header {
        flex-direction: column;
        text-align: center;
    }

    .app__wrapper_info {
        margin: 0;
        margin-bottom: 20px; /* Reduced margin */
    }

    .app__wrapper_img img {
        max-width: 80%;
    }

    .app-header {
        font-size: 3rem; /* Reduced font size */
    }
}

@media screen and (max-width: 768px) {
    .app-header {
        font-size: 2rem; /* Reduced font size */
    }
}

@media screen and (max-width: 480px) {
    .app-header {
        font-size: 1.5rem; /* Reduced font size */
    }
}
