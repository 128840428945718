body {
  font-family: 'Roboto', sans-serif;
}

.coffee-grid {
  display: flex;
  justify-content: space-around;
  padding-bottom: 100px;
  padding-left: 20px;
  flex-wrap: nowrap; /* Prevent wrapping */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.coffee-card {
  width: 250px;
  margin-right: 20px; /* Adjust spacing between cards */
  border: 1px solid #444;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #373734;
  color: #e8e4e4;
  transition: transform 0.3s;
}

.coffee-card:last-child {
  margin-right: 0; /* Remove right margin from last card */
}

.coffee-card:hover {
  transform: translateY(-5px);
}

.coffee-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

h3 {
  margin: 15px 0;
  font-size: 1.3rem;
  color: #ffd700;
}

p {
  font-size: 0.9rem;
  color: #ddd;
}

button {
  margin-top: 15px;
  padding: 12px 25px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
  font-family: 'Montserrat', sans-serif;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.coffee-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.coffee-modal-content {
  position: relative;
  background-color: #fff;
  padding: 15px;
  max-width: 80%;
  overflow-y: auto;
  z-index: 10000;
}

.close-button {
  position: absolute;
  top: 8px;
  width: 25px;
  right: 8px;
  font-size: 24px;
  cursor: pointer;
  color: #e90808;
}
