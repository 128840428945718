.chef__bg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px; /* Reduced padding */
}

.text-container {
  flex: 1;
  min-width: 300px;
  padding: 10px;
}

.image-container {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.chef__image {
  filter: brightness(70%);
  max-width: 80%; /* Reduced the max-width */
  height: auto;
  object-fit: cover;
}

.additional-content {
  margin-top: 20px;
  width: 100%;
}

.additional-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.additional-content li {
  margin-bottom: 10px;
  line-height: 1.5;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 1200px) {
  .text-container, .image-container {
    max-width: 100%;
    margin: 0 auto;
  }

  .chef__bg {
    flex-direction: column;
    text-align: center;
  }

  .chef__image {
    max-width: 60%; /* Further reduced the max-width for smaller screens */
  }

  .additional-content ul {
    padding-left: 10px;
  }

  .additional-content li {
    margin-bottom: 5px;
    line-height: 1.3;
  }
}

/* Reduce text size */
@media screen and (max-width: 768px) {
  .text-container h2 {
    font-size: 1.5rem; /* Reduced heading size */
  }

  .text-container p {
    font-size: 1rem; /* Reduced paragraph size */
  }

  .additional-content ul {
    font-size: 0.9rem; /* Reduced list item size */
  }
}
