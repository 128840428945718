.app__newsletter {
    padding: 1.5rem 2rem; /* Reduced padding */
    border: 1px solid var(--color-golden);
    background: var(--color-black);
}

.app__newsletter-heading {
    text-align: center;
}

.app__newsletter-input {
    flex-direction: row;
    margin-top: 0rem; /* Reduced margin */
}

.app__newsletter-input input {
    width: 480px; /* Reduced width */
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 0.9rem; /* Reduced font-size */
    font-family: var(--font-base);
    color: var(--color-white);
    margin-right: 1rem; /* Reduced margin */
    padding: 0.5rem 0.75rem; /* Reduced padding */
    background: var(--color-black);
}

.app__newsletter-input button {
    width: max-content;
}

@media screen and (min-width: 2000px) {
    .app__newsletter-input input {
        font-size: 1.5rem; /* Reduced font-size */
    }
}

@media screen and (max-width: 990px) {
    .app__newsletter-input {
        flex-direction: column;
        width: 100%;
        padding: 2rem;
    }

    .app__newsletter-input input {
        margin: 0 0 0.5rem 0; /* Reduced margin */
        width: 100%;
       
    }
}

@media screen and (max-width: 650px) {
    .app__newsletter {
        padding: 1.5rem 0; /* Reduced padding */
        border: none;
    }
}

@media screen and (max-width: 300px) {
    .app__newsletter-heading h1 {
        font-size: 28px; /* Reduced font-size */
        line-height: 40px; /* Reduced line-height */
    }
}
